/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1deba6f3-998d-4769-b6fa-6e345efa8256",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6Dp43rYlg",
    "aws_user_pools_web_client_id": "2uhrimg0ebmmsavl9mdvm10l25",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "s3uploaderui3636c9abcb774a3ea9987d39536da4d5113638-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "s3uploaderui-20230725113822-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1cl8qbp2v4vzu.cloudfront.net"
};


export default awsmobile;
